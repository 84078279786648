// React component with a form to create a new member of the health team
import React, { useEffect, useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { navigate } from "gatsby-link";
import styled from "styled-components";
import Swal from "sweetalert2";

import PrivateRoute from "~/components/Authentication/PrivateRoute";
import SButton from "~/components/Buttons/SButton";
import Flex from "~/components/Containers/Flex";
import Wrapper from "~/components/Containers/Wrapper";
import Error from "~/components/Errors/Error";
import alliancesService from "~/utils/api/v1/alliancesService";
import appointmentService from "~/utils/api/v1/appointmentService";
import packService from "~/utils/api/v1/packService";
import { countriesNoTilde } from "~/utils/data/constants";
import Pack from "~/utils/interfaces/Pack";
import PackCategory from "~/utils/interfaces/PackCategory";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  justify-content: center;

  ${(props: { margin?: string }) => {
    if (props.margin) {
      return `margin: ${props.margin};`;
    }
  }}

  .MuiFormControl-root {
    margin: 5px 10px;
  }
`;

interface Props {
  id: string;
}

const EditPack = ({ id }: Props): JSX.Element => {
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [categories, setCategories] = useState<PackCategory[]>([]);
  const [salesSourcesList, setSalesSourcesList] = useState<string[]>([]);
  const [packData, setPackData] = useState<Pack>({
    id: "",
    active: false,
    fonasa: false,
    promotion: false,
    country: "Chile",
    category: "",
    cover_image_link: "",
    description: "",
    final_price: 0,
    strikethrough_price: 0,
    name: "",
    number_of_patients: 0,
    results_time: 24,
    services: [],
    sources: [],
    stackable: false,
    tag: "",
    web_display_order: 0,
    external_reference: "",
  });

  const onSubmit = async () => {
    setLoading(true);
    const errors = checkErrors();
    if (errors) {
      setLoading(false);
      return;
    }

    try {
      const req = await packService.updatePack(id, packData);
      Swal.fire({
        icon: req.status === 200 ? "success" : "error",
        title: req.status === 200 ? "Pack actualizado con éxito" : "Error al actualizar pack",
        text:
          req.status === 200
            ? `Información del pack ${packData.name} actualizada con éxito`
            : "Error al actualizar el pack",
        showConfirmButton: true,
        didClose: () => {
          navigate(`/packs/${id}/`);
        },
      });
    } catch (err) {
      setErrors([err.message]);
    }

    setLoading(false);
  };

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    setPackData((prevState: Pack): Pack => {
      const newState = { ...prevState };
      newState[attribute] = newValue;
      return newState;
    });
  };

  const checkErrors = () => {
    const newErrors: string[] = [];

    // check for errors in the form
    if (!packData.name.length) {
      newErrors.push("El nombre es obligatorio");
    }
    if (packData.final_price <= 0) {
      newErrors.push("El precio final es incorrecto");
    }
    if (packData.strikethrough_price && packData.strikethrough_price < 0) {
      newErrors.push("El precio de referencia es incorrecto");
    }
    if (packData.results_time && packData.results_time < 0) {
      newErrors.push("El tiempo de resultados es incorrecto");
    }
    if (packData.number_of_patients && packData.number_of_patients < 0) {
      newErrors.push("El número de pacientes es incorrecto");
    }
    if (!packData.description) {
      newErrors.push("La descripción es obligatoria");
    }
    if (!packData.tag) {
      newErrors.push("El tag es obligatorio");
    }
    if (!packData.category) {
      newErrors.push("La categoría es obligatoria");
    }
    if (!packData.sources) {
      newErrors.push("Las sales sources son obligatorias");
    }

    setErrors(newErrors);
    return newErrors.length ? true : false;
  };

  useEffect(() => {
    const fetchCategories = async (): Promise<void> => {
      try {
        const res = await packService.fetchCategories();
        setCategories(res.data);
      } catch (err) {
        setErrors([err.message]);
      }
    };

    const fetchPackData = async () => {
      setLoading(true);
      try {
        const req = await packService.fetchPack(id);
        const data: Pack = req.data;
        setPackData(data);
      } catch (err) {
        setErrors([err.message]);
      }
      setLoading(false);
    };

    const fetchSalesSources = async () => {
      try {
        const res = await appointmentService.fetchSalesSources();
        const salesSourceList = res.data.data;
        const clientsRes = await alliancesService.fetchClients();
        const clients = clientsRes.data;
        const clientsSalesSource = clients.map((client) => client.sales_source);
        const mergedSaleSources = new Set<string>(salesSourceList.concat(clientsSalesSource));
        const sortedMergedSaleSources = Array.from(mergedSaleSources).sort((a, b) => {
          return a.localeCompare(b);
        });
        setSalesSourcesList(sortedMergedSaleSources);
      } catch (err) {
        setErrors([err.message]);
      }
    };

    fetchCategories();
    fetchSalesSources();
    fetchPackData();
  }, []);

  return (
    <PrivateRoute>
      <Flex justify="center">
        <Wrapper variant="outlined">
          <h2>Actualizar pack</h2>
          <Row hidden={!loading}>
            <h4>Cargando información</h4>
          </Row>

          <Error>
            <ul>
              {errors.map((i, key) => (
                <li key={key}>{i}</li>
              ))}
            </ul>
          </Error>

          <Row>
            <TextField
              id="name"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.name}
              helperText="Nombre del pack"
              fullWidth
            />
            <TextField
              id="description"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.description}
              helperText="Descripción"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="final_price"
              type="number"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.final_price}
              helperText="Precio final"
              fullWidth
            />
            <TextField
              id="strikethrough_price"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.strikethrough_price}
              helperText="Precio de referencia, tachado"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="cover_image_link"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.cover_image_link}
              helperText="Link de la imagen de portada"
              fullWidth
            />
            <TextField
              id="results_time"
              type="number"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.results_time}
              helperText="Tiempo de resultados"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="number_of_patients"
              type="number"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.number_of_patients}
              helperText="Número de pacientes"
              fullWidth
            />
            <TextField
              id="web_display_order"
              type="number"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.web_display_order}
              helperText="Posición en la web"
              fullWidth
            />
          </Row>
          <Row>
            <TextField
              id="tag"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.tag}
              helperText="Tag"
              fullWidth
            />
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="stackable">Stackable</InputLabel>
              <Select
                disabled={loading}
                value={packData.stackable}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    stackable: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value="true"
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value="false"
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="fonasa">Fonasa</InputLabel>
              <Select
                disabled={loading}
                value={packData.fonasa}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    fonasa: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value="true"
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value="false"
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="country">País</InputLabel>
              <Select
                disabled={loading}
                value={packData.country}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    country: e.target.value as string,
                  });
                }}
                fullWidth
              >
                {countriesNoTilde.map((country) => (
                  <MenuItem
                    key={country}
                    value={country}
                  >
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="promotion">Promoción</InputLabel>
              <Select
                disabled={loading}
                value={packData.promotion}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    promotion: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value="true"
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value="false"
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="category">Categoría</InputLabel>
              <Select
                disabled={loading}
                value={packData.category}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    category: e.target.value as string,
                  });
                }}
                fullWidth
              >
                {categories.map((category) => (
                  <MenuItem
                    key={category.id}
                    value={category.id}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="active">Activo</InputLabel>
              <Select
                disabled={loading}
                value={packData.active}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    active: e.target.value as boolean,
                  });
                }}
                fullWidth
              >
                <MenuItem
                  key="true"
                  value="true"
                >
                  Si
                </MenuItem>
                <MenuItem
                  key="false"
                  value="false"
                >
                  No
                </MenuItem>
              </Select>
            </FormControl>
          </Row>
          <Row>
            <FormControl fullWidth>
              <InputLabel id="sources">Sources</InputLabel>
              <Select
                disabled={loading}
                value={packData.sources || []}
                onChange={(e) => {
                  setPackData({
                    ...packData,
                    sources: e.target.value as string[],
                  });
                }}
                multiple
                fullWidth
              >
                {salesSourcesList.map((salesSource) => (
                  <MenuItem
                    key={salesSource}
                    value={salesSource}
                  >
                    {salesSource}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Row>
          <Row>
            <TextField
              id="external_reference"
              disabled={loading}
              placeholder={loading ? "Cargando..." : ""}
              onChange={formChange}
              value={packData.external_reference}
              helperText="External reference"
              fullWidth
            />
          </Row>
          <Flex
            align="center"
            justify="center"
            margin="2rem 0rem 1rem 0rem"
          >
            <SButton
              variant="outlined"
              color="secondary"
              disabled={loading}
              onClick={() => {
                navigate(`/packs/${id}/`);
              }}
            >
              Cancelar
            </SButton>
            <SButton
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={onSubmit}
            >
              Actualizar
            </SButton>
          </Flex>
        </Wrapper>
      </Flex>
    </PrivateRoute>
  );
};

export default EditPack;
