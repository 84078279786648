import { AxiosResponse } from "axios";
import qs from "qs";

import api from "~/utils/api/api";
import { CartItemObjectType } from "~/utils/interfaces/cartItem";
import { GetItemsApiServiceProps, RequestResponse } from "~/utils/interfaces/request";
import { GetRawDataItemPayload } from "~/utils/interfaces/Services";
import { parseItems } from "~/utils/parser/services";

const packsBaseUrl = "exam-packs/packs";
const packCategoriesBaseUrl = "exam-packs/pack-category";
const servicesUrl = "services";

class PackService {
  async createPack(data: Object): Promise<AxiosResponse> {
    return api.post(`${packsBaseUrl}/`, (data = data));
  }

  async updatePack(id: string, data: Object): Promise<AxiosResponse> {
    return api.patch(`${packsBaseUrl}/${id}/`, (data = data));
  }

  async deletePack(id: string): Promise<AxiosResponse> {
    return api.delete(`${packsBaseUrl}/${id}/`);
  }

  fetchPacks = async ({ signal, params }: GetItemsApiServiceProps): Promise<RequestResponse<CartItemObjectType[]>> => {
    const res = await api.get<GetRawDataItemPayload[]>(
      `/v2/packs/?${qs.stringify(params, {
        arrayFormat: "repeat",
        addQueryPrefix: false,
        encode: false,
        indices: false,
      })}`,
      { signal },
    );
    const data = parseItems(res.data.filter(({ price }) => price));
    return { data };
  };

  fetchPacksBySource = async ({ signal, params }: GetItemsApiServiceProps): Promise<AxiosResponse> => {
    const LAST_MILE_SALES_SOURCES = [
      "medicalima",
      "laboratoriochopo",
      "mediqo",
      "farmapronto",
      "fertygen",
      "grupomok",
      "somos",
    ];
    const { source } = params;
    const sources = [source];
    if (!LAST_MILE_SALES_SOURCES.includes(source)) {
      sources.push("marketplace");
    }
    const res = await api.get<GetRawDataItemPayload[]>(
      `/v2/packs/?${qs.stringify(params, {
        arrayFormat: "repeat",
        addQueryPrefix: false,
        encode: false,
        indices: false,
      })}`,
      { signal },
    );

    const data = parseItems(res.data.filter(({ price }) => price));
    return { data };
  };

  async fetchPack(id: string): Promise<AxiosResponse> {
    return api.get(`${packsBaseUrl}/${id}/`);
  }

  async fetchCategories(): Promise<AxiosResponse> {
    return api.get(`${packCategoriesBaseUrl}/`);
  }

  async addServiceToPack(packId: string, data: Object): Promise<AxiosResponse> {
    return api.post(`${packsBaseUrl}/${packId}/add_lab_services/`, (data = data));
  }

  async removeServiceFromPack(packId: string, data: Object): Promise<AxiosResponse> {
    return api.patch(`${packsBaseUrl}/${packId}/remove_lab_services/`, (data = data));
  }

  async fetchServices(): Promise<AxiosResponse> {
    return api.get(`${servicesUrl}/`);
  }
}

const packService = new PackService();

export default packService;
