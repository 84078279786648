import { AxiosResponse } from "axios";

import api from "~/utils/api/api";

const alliancesBaseUrl = "alliances";

class AlliancesService {
  fetchCompanies = async (companyName: string): Promise<AxiosResponse<any>> => {
    return api.get(`${alliancesBaseUrl}/companies/`, {
      params: { search: companyName },
    });
  };

  fetchClients = async (): Promise<AxiosResponse<any>> => {
    return api.get(`${alliancesBaseUrl}/clients/`);
  };
  fetchSimpleClients = async (): Promise<AxiosResponse<any>> => {
    return api.get(`v2/clients/?use_simple=True`);
  };

  fetchClientOrigins = async (sales_source: string): Promise<AxiosResponse<{ origins: string[] }>> => {
    return api.get(`${alliancesBaseUrl}/clients/origins/`, { params: { sales_source } });
  };
}

const alliancesService = new AlliancesService();
export default alliancesService;
